import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import SubPage from './components/subPage';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import './Home.css'

function Item(props) {

	const [hover, setHover] = useState(false);

	const toggleHover = () => {
		if(hover) setHover(false)
		else setHover(true)
	}

	return (
		<li key={props.record.id} 
				onMouseEnter={() => toggleHover()} 
				onClick={() => props.handleTopMenu(props.record)}>
				{ hover ? <i>{props.record.fields['Alt_Name']}</i> : props.record.fields['Field']}
		</li>
	)
}


function Archive(props) {

  const params = useParams();
	const [mutated, setMutated] = useState([]);
	const [subPageUrl, setSubPageUrl] = useState(null);
	const [subPage, setSubPage] = useState(null);
	const [projects, setProjects] = useState([]);
	const [menu, setMenu] = useState([]);

	const handleTopMenu = (item) => {
		setSubPageUrl(props.processUrl(item.fields.Field))
		window.history.replaceState(null, "tile", '/all-work/' + props.processUrl(item.fields.Field))
	}

	useEffect(() => {
		const { field } = params ? params : null;
		setSubPageUrl(field);
	}, [params])

	useEffect(() => {
		let menuList = props.menu.filter(record => record.fields['Ranking'] === "second")
		menuList.map(item => item.mutated = false)
		setMenu(menuList)
	}, [props.menu])

	useEffect(() => {
		const page = props.menu.length > 0 && props.menu.find(record => subPageUrl === props.processUrl(record.fields.Field))
		page !== null && setSubPage(page)
	}, [subPageUrl])

	useEffect(() => {
		const projectList = subPage ? props.projects.filter(project =>  project.fields.Menus.includes(subPage.id)) : null

		if(projectList) {
			projectList.sort( (a, b) => { 
				return Date.parse(b.fields['Date From']) - Date.parse(a.fields['Date From'])})
			setProjects(projectList)
		}
	}, [subPage, props.projects])

		return (
			<div className="main">
		 		{ props.isLoading ? 'loading' : 
		 		<>
		 		<BrowserView>
			 	{ subPage ? <h1>all work -> {subPage.fields.Field.toLowerCase() + " / " + subPage.fields.Alt_Name.toLowerCase()}</h1> : <h1>all work</h1>}
		 		<div className="menus">
			 		<div id="main" className="menu">
						<ul>
							{ menu.length > 0 && menu.map( (record, i) =>
								record.fields && <Item key={i} record={record} handleTopMenu={handleTopMenu}/>
							)}
							<Link to="/"><li>{"<- Home"}</li></Link>
						</ul>
						{ subPage && subPage.fields.Image && <img src={subPage.fields.Image[0].thumbnails.large.url} className="header-image"/>}
					</div>
					{subPage && <SubPage projects={projects} page={subPage} processUrl={props.processUrl}/>}
					</div>
					</BrowserView>
					<MobileView>
			 	<h1>all work</h1>
			 		<div>
				 		<div id="main" className="menu">
							<ul>
								{ menu.length > 0 && menu.map( (record, i) =>
									record.fields && <Item key={i} record={record} handleTopMenu={handleTopMenu}/>
								)}
								<Link to="/"><li>{"<- Home"}</li></Link>
							</ul>
							{ subPage && subPage.fields.Image && <img src={subPage.fields.Image[0].thumbnails.large.url} className="header-image"/>}
						</div>
						{ subPage && <h1>-> {subPage.fields.Field.toLowerCase() + " / " + subPage.fields.Alt_Name.toLowerCase()}</h1>}
						{subPage && <SubPage projects={projects} page={subPage} processUrl={props.processUrl}/>}
						</div>
					</MobileView>
				</>
			}
			</div>
		)
	}

export default Archive;