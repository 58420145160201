import React from 'react';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function SubPage(props) {
	return (
		<>
		<BrowserView>
			<div className="main-text">
			<ReactMarkdown>{props.page.fields.Text}</ReactMarkdown>
			{props.projects.length > 0 && 
				<ul className="project-list">
					{props.projects.map(project => <Link to={"/" + props.processUrl(project.fields.Name)}><li>{project.fields.Name}</li></Link>)} 
				</ul>
			}
			</div>
		</BrowserView>
		<MobileView>
			<div className="main-text-mobile">
			<ReactMarkdown>{props.page.fields.Text}</ReactMarkdown>
			{props.projects.length > 0 && 
				<ul className="project-list-mobile">
					{props.projects.map(project => <Link to={"/" + props.processUrl(project.fields.Name)}><li>{project.fields.Name}</li></Link>)} 
				</ul>
			}
			</div>
		</MobileView>
		</>
	)
}

export default SubPage