import React from 'react';

export default class Contact extends React.Component{

	render() {

		return (
			<div>
			contact
			</div>
		)
	}
}