import React, {useEffect} from 'react';
import { useNavigate, Link } from "react-router-dom";

function Image(props) {

}


function Gallery(props){
	
	console.log(props.visual)

	return (
		<div className="main-gallery">
			<ul>
			{ props.visual.length > 0 && props.visual.map( item =>
				<Link to={ props.processUrl(item.fields.Name[0]) }>
				<img src={item.fields.Image[0].thumbnails.large.url}/>
					<p className="image-caption">{item.fields.Name} / credit: {item.fields.Credit}</p>
				</Link>
			)}
			</ul>
		</div>
	)
}

export default Gallery;