import React, {useState, useEffect} from 'react';
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import './TooMuch.css'

function TooMuch(props) {

	const [projects, setProjects] = useState([])

	useEffect(() => {
		const proj =  props.page ? props.projects.filter(project =>  project.fields.Menus.includes(props.page.id)) : null
		proj && setProjects(proj)
	}, [props.projects, props.page])

	return (
		<div className="too-much-container">
		<BrowserView>
			<div className="main">
				{ props.page ? <h1>hannah calascione -> {props.page.fields.Field.toLowerCase()}</h1> : <h1>hannah calascione</h1>}
				<Link to="/"><h1 className="less-much">X</h1></Link>
				<div className="too-much">
					<div className="main-div">
						<h1>TOO MUCH</h1>
						{ props.page && <ReactMarkdown>{props.page.fields.Text}</ReactMarkdown> }
						{ projects.length > 0 && 
							<ul className="project-list">
								{projects.map(project => <Link to={"/" + props.processUrl(project.fields.Name)}><li>{project.fields.Name}</li></Link>)}
							</ul>
						}
					</div>
				</div>
			</div>
		</BrowserView>
		<MobileView>
			<div className="main">
				{ props.page ? <h1>hannah calascione -> {props.page.fields.Field.toLowerCase()}</h1> : <h1>hannah calascione</h1>}
				<Link to="/"><h1 className="less-much">X</h1></Link>
				<div className="too-much-mobile">
					<h1>TOO MUCH</h1>
					{ props.page && <ReactMarkdown>{props.page.fields.Text}</ReactMarkdown> }
					{ projects.length > 0 && 
						<ul className="project-list-mobile">
							{projects.map(project => <Link to={"/" + props.processUrl(project.fields.Name)}><li>{project.fields.Name}</li></Link>)}
						</ul>
					}
				</div>
			</div>
		</MobileView>
		</div>
	)
}

export default TooMuch;