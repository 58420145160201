import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown'
import { TwitterEmbed, InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


function News(props){

	const sortUrl = (string) => {
		if(string.indexOf('<blockquote') === -1) return false
		else {
			return true
		}
	}

	useEffect(() => {
    	const script = document.createElement('script');
    	// Fix for Instagram embeds https://github.com/justinmahar/react-social-media-embed/issues/41#issuecomment-1622120110
    	script.src = "//www.instagram.com/static/bundles/metro/EmbedSDK.js/d9addf525b6a.js";
    	script.async = true;
    	document.body.appendChild(script);
  	}, []);

	
	return (
		<>
			<BrowserView>
				<div className="main-news">
					<ul className="news-list">
					{ props.news.length > 0 && props.news.sort(function(a,b){
						  return new Date(b.fields.Date) - new Date(a.fields.Date);
						}).map( item => 
						<li><p><b>{item.fields.Date} -> {item.fields.Title}</b></p>
						{sortUrl(item.fields.Text) ? 
							<div dangerouslySetInnerHTML={{__html: item.fields.Text }}></div> :
							<p><ReactMarkdown> {item.fields.Text}</ReactMarkdown></p>
						}
						</li>
					)}
					</ul>
				</div>
			</BrowserView>
			<MobileView>
				<div className="main-news-mobile">
					<ul className="news-list-mobile">
					{ props.news.length > 0 && props.news.sort(function(a,b){
						  return new Date(b.fields.Date) - new Date(a.fields.Date);
						}).map( item => 
						<li><p><b>{item.fields.Date} -> {item.fields.Title}</b></p>
						{sortUrl(item.fields.Text) ? 
							<div dangerouslySetInnerHTML={{__html: item.fields.Text }}></div> :
							<p><ReactMarkdown> {item.fields.Text}</ReactMarkdown></p>
						}
						</li>
					)}
					</ul>
				</div>
			</MobileView>
		</>
	)
}

export default News;