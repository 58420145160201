import React, {useState, useEffect} from 'react';
import TooMuch from './components/TooMuch';
import About from "./About";
import News from './News';
import Gallery from './Gallery';
import SubPage from './components/subPage';
import { useNavigate, Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import './Home.css'

function Home(props) {
	const [subPage, setSubPage] = useState(null);
	const [showNews, setShowNews] = useState(false);
	const [showGallery, setShowGallery] = useState(true);
	const [projects, setProjects] = useState([]);

  const navigate = useNavigate()

	const handleTopMenu = (menu) => {
		setShowGallery(false);
		switch(menu.fields['Field']){
			case 'All Work':
				navigate('/all-work');
				break;
			case 'Company':
				navigate('/company');
				break;
			case 'News':
				setSubPage(null);
				setShowNews(true);
				break;
			default:
				setSubPage(menu);
				setShowNews(false);
				break;
		}

	}

	useEffect(() => {
		const proj = subPage ? props.projects.filter(project =>  project.fields.Menus.includes(subPage.id)) : null
		proj && setProjects(proj)
	}, [subPage, props.isLoading, props.projects])


		return (
			<>
			<BrowserView>
				<div className="main">
			 		{ props.isLoading ? 'loading' : 
			 		<>
				 	{ subPage ? <h1>hannah calascione -> {subPage.fields.Field.toLowerCase()}</h1> : <h1>hannah calascione</h1>}
			 		<div className="menus">
				 		<div id="main" className="menu">
							<ul>
								{
								props.menu.length > 0 && props.menu.filter(rec => rec.fields['Ranking'] === "first").map( (record, i) =>
									record.fields && <li onClick={() => handleTopMenu(record)} key={record.id}>{record.fields['Field']}</li>)
								}
							</ul>

							{ subPage && subPage.fields.Image && <img src={subPage.fields.Image[0].thumbnails.large.url} className="header-image"/>}
						</div>
					{ subPage && <SubPage projects={projects} page={subPage} processUrl={props.processUrl} />}
					{ showNews && <News news={props.news} />}
					{ showGallery && <Gallery visual={props.visual} processUrl={props.processUrl} />}
				</div>
				</>
				}
				</div>
			</BrowserView>
			<MobileView>
				<div className="mobile-main">
					<h1>hannah calascione</h1>
				 		<div id="main" className="menu">
							<ul>
								{
								props.menu.length > 0 && props.menu.filter(rec => rec.fields['Ranking'] === "first").map( (record, i) =>
									record.fields && <li onClick={() => handleTopMenu(record)} key={record.id}>{record.fields['Field']}</li>)
								}
							</ul>
							{ subPage && <h1>-> {subPage.fields.Field.toLowerCase()}</h1> }
							{ showNews && <News news={props.news} />}
							{ subPage && subPage.fields.Image && <img src={subPage.fields.Image[0].thumbnails.large.url} className="header-image"/>}
							{ subPage && <SubPage projects={projects} page={subPage} processUrl={props.processUrl} />}
						</div>
				</div>
			</MobileView>
			</>
		)

}

export default Home;