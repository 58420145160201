import React from 'react';
import { Link } from "react-router-dom";

function ListPage(props) {
	console.log(props.projects)
	return (
		<div>hello</div>
	)
}

export default ListPage