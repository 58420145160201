import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import dotenv from 'dotenv'; 
import './App.css';
import $ from 'jquery'
import Home from './Home';
import NotFound from './NotFound';
import Archive from './Archive';
import ListPage from './components/ListPage';
import TooMuch from './components/TooMuch';
import News from './News';
import Project from './Project';
import Contact from './Contact';
const Airtable = require('airtable');

// configure env variables
dotenv.config()

const process_api_key = process.env.REACT_APP_AIRTABLE_API_KEY;
const airtable_base = process.env.REACT_APP_AIRTABLE_BASE;

function App() {

	const [projects, setProjects] = useState([]);
	const [menu, setMenu] = useState([]);
	const [news, setNews] = useState([]);
	const [press, setPress] = useState([]);
	const [visual, setVisual] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	Airtable.configure({
			endpointUrl: 'https://api.airtable.com',
			apiKey: process_api_key
	});

	const base = Airtable.base(airtable_base);

	const processUrl = (string) => {
		let proc_url = ''

		try {
			proc_url = string.trim().replace(/[^\w\s']|_/g, "").replace(/\s+/g, "_").toLowerCase()
		}
		catch(err) {
			console.log(err)
		}

		return proc_url
	}

	//get menu first
	useEffect(()=>{
			base('Menu').select().all().then(records => {
					console.log(records)
					setMenu(records.sort( (a, b) => a.fields.Order - b.fields.Order ));
			})
			.then(setIsLoading(false))
			.catch(err => {
					console.error(err);
			});

	},[])

	//then get projects
	useEffect(()=>{
			base('Projects').select().all().then(records => {
					const filtered = records.filter(record => record.fields.Display === true)
					setProjects(filtered);
			})
			.catch(err => {
					console.error(err);
			});
	},[])

	useEffect(()=>{
			base('News').select().all().then(records => {
					setNews(records)
			})
			.catch(err => {
					console.error(err);
			});
	},[])	

	useEffect(()=>{
			base('Press').select().all().then(records => {
					setPress(records)
			})
			.catch(err => {
					console.error(err);
			});
	},[])	

	useEffect(()=>{
			base('Visual').select().all().then(records => {
					setVisual(records)
			})
			.catch(err => {
					console.error(err);
			});
	},[])	

	return (
		<div className="App">
			<Router>
				<Helmet>
					<meta name="description" content="hannah calascione's web site" />
					<meta name="keywords" content="hannah calascione" />
				</Helmet>
					<Routes>
						<Route exact path="/" element={<Home processUrl={processUrl} news={news} visual={visual} isLoading={isLoading} menu={menu} projects={projects}/>}/>
						<Route exact path="/all-work" element={<Archive isLoading={isLoading} menu={menu} processUrl={processUrl} projects={projects}/>}/>
						<Route exact path="/recent" element={<ListPage isLoading={isLoading} entry={menu.find( item => item.fields.Field === 'Recent Work')} projects={projects}/>}/>
						<Route exact path="/contact" element={<Contact isLoading={isLoading}/>}/>
						<Route exact path="/not-found" element={<NotFound/>}/>
						<Route exact path="/company" element={<TooMuch projects={projects} page={menu.find( item => item.fields.Field === 'Company')} processUrl={processUrl}/>}/>
						<Route exact path="/news" element={<News isLoading={isLoading}/>}/>
						{ menu.length > 0 && menu.filter( item => item.fields.Ranking === 'second').map( item =>
							<Route path={"/all-work/:field"} element={<Archive menu={menu} isLoading={isLoading} entry={item}  processUrl={processUrl} projects={projects}/>}/> 
						)}
						<Route path="/:id"element={<Project processUrl={processUrl} press={press} isLoading={isLoading} projects={projects}/>}/>
					</Routes>
			</Router>
		</div>
	);
}

export default App;